/** HTML elements styles */
/* INCLUDE variables */
/* Grey */
/* White */
/* Black */
/* Site */
h2 {
  color: #00b5e4;
  font-size: 0.9em;
  text-transform: uppercase; }

a {
  color: #00b5e4;
  text-decoration: none; }

a:hover {
  color: #fbb900; }

strong {
  font-weight: 600; }

em {
  font-style: italic; }

label {
  font-weight: 600; }

img {
  max-width: 100%; }

table {
  width: 100%; }
  table thead {
    border-bottom: 2px solid #184353;
    color: #184353; }
    table thead th {
      font-weight: 600;
      text-align: left; }

input[type="email"],
input[type="num"],
input[type="text"],
input[type="password"],
select,
textarea {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #184353;
  font-family: "Open Sans", Verdana, sans-serif;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  max-width: 100%;
  padding: 10px 8px; }

textarea {
  height: auto; }

input:focus,
select:focus,
textarea:focus {
  -webkit-box-shadow: 0 0 0 1px #eef1f4;
  -moz-box-shadow: 0 0 0 1px #eef1f4;
  box-shadow: 0 0 0 1px #eef1f4;
  outline: none; }

.button-primary,
input[type="button"],
input[type="submit"] {
  background: #fbb900;
  border: 0;
  border-radius: 0;
  color: #184353;
  font-size: 13px;
  font-weight: 700;
  padding: 6px; }

blockquote {
  border-left: 3px solid #184353;
  margin: 15px;
  padding-left: 15px; }

a.tabledrag-handle .handle {
  background: url(/misc/draggable.png) no-repeat 6px 9px;
  height: 32px;
  margin: -0.4em 0.5em;
  padding: 0.42em 0.5em;
  width: 32px; }

.view-locatie-youtube {
  overflow: auto; }
  .view-locatie-youtube .views-row {
    float: left;
    padding: 10px;
    overflow: auto;
    width: 50%; }
    .view-locatie-youtube .views-row .fluid-width-video-wrapper iframe,
    .view-locatie-youtube .views-row .fluid-width-video-wrapper object,
    .view-locatie-youtube .views-row .fluid-width-video-wrapper embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.col-3 {
  width: 25%; }

.col-6 {
  width: 50%; }

.col-12 {
  width: 100%; }

@media screen and (max-width: 600px) {
  section.main-content.sub-page .field-name-field-pagina-afbeelding {
    float: none;
    padding-left: 0;
    width: 100%; }
  .view-locatie-youtube .views-row {
    float: none;
    width: 100%; } }

.view-nieuws-blogs {
  text-align: center; }
  .view-nieuws-blogs .views-row {
    display: inline-block;
    margin: 0 13px 50px 13px;
    position: relative;
    vertical-align: top;
    width: 300px; }
    .view-nieuws-blogs .views-row .views-field-field-datum {
      background: #fbb800;
      color: #ffffff;
      display: block;
      position: absolute;
      padding: 10px 10px 10px 12px;
      right: 0;
      top: 0; }
    .view-nieuws-blogs .views-row .views-field-title,
    .view-nieuws-blogs .views-row .views-field-body,
    .view-nieuws-blogs .views-row .views-field-nothing {
      text-align: left; }
    .view-nieuws-blogs .views-row .views-field-title h2 {
      font-size: 16px;
      font-weight: bold; }
    .view-nieuws-blogs .views-row .views-field-nothing {
      margin-top: 20px; }
      .view-nieuws-blogs .views-row .views-field-nothing a.button-yellow {
        background: #fbb900;
        border: 0;
        border-radius: 0;
        color: #184353;
        font-size: 13px;
        font-weight: 700;
        padding: 6px;
        transition: all 0.2s linear 0s; }
        .view-nieuws-blogs .views-row .views-field-nothing a.button-yellow:hover {
          text-decoration: underline;
          transition: all 0.2s linear 0s; }

.block-prev-next {
  border-top: 1px solid #999;
  margin: 10px auto;
  padding: 20px;
  text-align: center;
  width: 80%; }
  .block-prev-next .prev-next-link-prev {
    margin-right: 40px; }
  .block-prev-next .prev-next-link-next {
    margin-left: 40px; }

.view-colleague-tells-overview .views-field-title {
  margin-top: 20px; }

.view-colleague-tells-overview .views-field-body {
  margin-bottom: 40px; }

.field-name-field-youtube {
  margin-bottom: 20px; }

#block-views-exp-zoekresultaten-page div.content {
  margin-top: -7px; }

#block-views-exp-zoekresultaten-page .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0; }

#block-views-exp-zoekresultaten-page .views-exposed-form .form-submit {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -10px; }

html {
  overflow-y: scroll; }

body {
  color: #184353;
  font-family: "Open Sans", Verdana, sans-serif;
  font-weight: 500;
  line-height: 1.5em; }

/** Re-usable classes */
.wrapper {
  margin: 0 auto;
  max-width: 1024px;
  width: 95%; }

ul.menu {
  list-style-image: none;
  list-style-type: none; }
  ul.menu li {
    cursor: pointer;
    list-style-image: none;
    list-style-type: none; }
    ul.menu li a {
      color: #184353; }
    ul.menu li a:hover {
      color: #fbb900; }

ol {
  list-style: decimal;
  margin: 0.5em 2em; }
  ol li {
    list-style: decimal; }

.clear {
  clear: both; }

/** Drupal controls */
ul.primary {
  margin: 0; }

.breadcrumb-wrapper {
  font-size: 0.8em;
  padding-bottom: 1.5em; }
  .breadcrumb-wrapper .breadcrumb {
    display: inline-block; }
    .breadcrumb-wrapper .breadcrumb a {
      color: #8898a3; }

/** Loading animation */
.loading-animation {
  background: #00b5e4;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999; }
  .loading-animation .spinner {
    margin: 100px auto;
    width: 60px;
    height: 60px;
    position: relative;
    top: 30%; }
  .loading-animation .container1 > div, .loading-animation .container2 > div, .loading-animation .container3 > div {
    width: 12px;
    height: 12px;
    background-color: #184353;
    border-radius: 100%;
    position: absolute;
    -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
    animation: bouncedelay 1.2s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .loading-animation .spinner .spinner-container {
    position: absolute;
    width: 100%;
    height: 100%; }
  .loading-animation .container2 {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg); }
  .loading-animation .container3 {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg); }
  .loading-animation .circle1 {
    top: 0;
    left: 0; }
  .loading-animation .circle2 {
    top: 0;
    right: 0; }
  .loading-animation .circle3 {
    right: 0;
    bottom: 0; }
  .loading-animation .circle4 {
    left: 0;
    bottom: 0; }
  .loading-animation .container2 .circle1 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s; }
  .loading-animation .container3 .circle1 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }
  .loading-animation .container1 .circle2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s; }
  .loading-animation .container2 .circle2 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s; }
  .loading-animation .container3 .circle2 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s; }
  .loading-animation .container1 .circle3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s; }
  .loading-animation .container2 .circle3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }
  .loading-animation .container3 .circle3 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s; }
  .loading-animation .container1 .circle4 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s; }
  .loading-animation .container2 .circle4 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s; }
  .loading-animation .container3 .circle4 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s; }

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/** Header */
body.adminimal-menu header {
  margin-top: 49px; }

header {
  margin-bottom: 30px;
  margin-top: 19px;
  position: relative;
  /** Header search */
  /** Navigation */ }
  header section {
    float: left; }
    header section .block {
      float: left; }
  header section.header-top {
    float: right; }
    header section.header-top ul.menu li {
      float: left;
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      padding-right: 15px;
      text-transform: uppercase; }
  header input[type="text"],
  header input[type="submit"] {
    background: #fbb900;
    border: 0;
    border-radius: 0;
    font-weight: 600;
    height: 26px;
    line-height: 21px;
    padding: 0 5px;
    text-transform: uppercase; }
  header input[type="text"] {
    padding-left: 4px;
    width: 187px; }
  header ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #184353; }
  header :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #184353;
    opacity: 1; }
  header ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #184353;
    opacity: 1; }
  header :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #184353; }
  header nav {
    bottom: 0;
    float: right;
    position: absolute;
    right: 0;
    /* Fix border-box bug in superfish */ }
    header nav select {
      background: url(../images/menu_sf.png) no-repeat left center #3498db;
      color: white;
      font-size: 120%;
      max-width: 240px; }
    header nav .sf-menu ul {
      overflow: visible !important; }
    header nav ul.menu,
    header nav ul.sf-menu {
      text-transform: uppercase; }
      header nav ul.menu li,
      header nav ul.sf-menu li {
        margin: 0; }
      header nav ul.menu a,
      header nav ul.sf-menu a {
        color: #8898a3;
        font-weight: 800;
        letter-spacing: 1px;
        padding-left: 40px; }
      header nav ul.menu a:hover,
      header nav ul.menu a.active,
      header nav ul.sf-menu a:hover,
      header nav ul.sf-menu a.active {
        color: #184353; }
      header nav ul.menu a.menuparent:after,
      header nav ul.sf-menu a.menuparent:after {
        bottom: 2px;
        color: #00b5e4;
        content: "\f078";
        font-family: 'FontAwesome';
        font-size: 0.7em;
        font-weight: 300;
        padding-left: 3px;
        position: relative; }
      header nav ul.menu li:first-child a,
      header nav ul.sf-menu li:first-child a {
        padding-left: 0; }
      header nav ul.menu li.sfHover ul,
      header nav ul.sf-menu li.sfHover ul {
        border: 1px solid #fbb900;
        border-top: 3px solid #00b5e4;
        box-shadow: 0px 4px 5px #184353;
        background: #ffffff;
        opacity: 0.95;
        top: 43px; }
        header nav ul.menu li.sfHover ul li ul,
        header nav ul.sf-menu li.sfHover ul li ul {
          top: -3px; }
        header nav ul.menu li.sfHover ul li a,
        header nav ul.sf-menu li.sfHover ul li a {
          border-bottom: 1px solid #8898a3;
          font-weight: 500;
          margin: 0 12px;
          padding: 6px;
          text-transform: none; }
        header nav ul.menu li.sfHover ul li a.menuparent:after,
        header nav ul.sf-menu li.sfHover ul li a.menuparent:after {
          bottom: -2px;
          content: "\f054";
          float: right; }
        header nav ul.menu li.sfHover ul li:hover,
        header nav ul.sf-menu li.sfHover ul li:hover {
          background: rgba(0, 181, 228, 0.1); }
        header nav ul.menu li.sfHover ul li:last-child a,
        header nav ul.sf-menu li.sfHover ul li:last-child a {
          border-bottom: 0; }
      header nav ul.menu li.active-trail > a,
      header nav ul.sf-menu li.active-trail > a {
        color: #184353; }

/** Page top image */
.view-pagina-afbeelding-boven img {
  box-shadow: 0 1px 1px #999999; }

/** Main content */
section.main-content.with-sidebar {
  float: left;
  margin-right: 2%;
  width: 70.66%; }

/* Search page */
.page-search-node section.main-content ol {
  list-style: none;
  margin: 0; }
  .page-search-node section.main-content ol li {
    list-style: none;
    margin-bottom: 0;
    padding: 1em; }
    .page-search-node section.main-content ol li .search-snippet-info {
      padding-left: 0; }
  .page-search-node section.main-content ol li:nth-child(even) {
    background: #dfe4ea; }

section.main-content {
  /** Page title */
  /* Search input */
  /* Landingspagina */ }
  section.main-content h1.page-title {
    color: #00b5e4;
    font-size: 1.6em;
    font-weight: 800;
    padding: 20px 15px 5px 15px;
    text-transform: uppercase; }
  section.main-content form.search-form input[type="text"],
  section.main-content form.search-form input[type="submit"] {
    background: #fbb900;
    border: 0;
    border-radius: 0;
    color: #184353;
    font-weight: 600;
    height: 26px;
    line-height: 21px;
    padding: 0 5px;
    text-transform: none; }
  section.main-content form.search-form input[type="text"] {
    padding-left: 4px;
    width: 187px; }
  section.main-content .view-landingspagina {
    margin-bottom: 2em;
    max-height: 500px;
    overflow: hidden;
    position: relative; }
    section.main-content .view-landingspagina .views-field-view {
      padding-left: 2.5%;
      position: absolute;
      top: 45%;
      width: 100%; }
      section.main-content .view-landingspagina .views-field-view .views-row {
        display: inline-flex;
        margin: 1%;
        width: 21.7%;
        /** Thema's */ }
        section.main-content .view-landingspagina .views-field-view .views-row .views-field-field-afbeelding-1 {
          display: none; }
        section.main-content .view-landingspagina .views-field-view .views-row .views-field-name {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          word-wrap: break-word; }
          section.main-content .view-landingspagina .views-field-view .views-row .views-field-name .field-content a {
            display: block;
            font-size: 1.3em;
            font-weight: 700;
            height: 180px;
            letter-spacing: 1px;
            line-height: 1.2em;
            padding: 10px;
            text-transform: uppercase; }
          section.main-content .view-landingspagina .views-field-view .views-row .views-field-name .thema-blauw a {
            background: #00b5e4;
            color: #eef1f4; }
          section.main-content .view-landingspagina .views-field-view .views-row .views-field-name .thema-geel a {
            background: #fbb900;
            color: #184353; }
  section.main-content .node ul {
    padding: 4px 0; }
    section.main-content .node ul li {
      text-indent: -15px;
      margin-left: 16px;
      padding-left: 16px; }
    section.main-content .node ul li:before {
      color: #00b5e4;
      content: "\f0da";
      font-family: 'FontAwesome';
      padding-right: 8px; }
  section.main-content .view-id-nieuws.view-display-id-page .views-row {
    border-bottom: 1px solid #8898a3;
    margin-bottom: 12px;
    padding-bottom: 12px; }
    section.main-content .view-id-nieuws.view-display-id-page .views-row .views-field-title h3,
    section.main-content .view-id-nieuws.view-display-id-page .views-row .views-field-title {
      display: inline-block;
      padding-bottom: 0.3em; }
    section.main-content .view-id-nieuws.view-display-id-page .views-row .views-field-field-datum {
      clear: right;
      float: right;
      font-size: 0.9em;
      font-style: italic; }
    section.main-content .view-id-nieuws.view-display-id-page .views-row .views-more-link {
      background: #00b5e4;
      color: #ffffff;
      display: inline-block;
      float: right;
      margin: 0.5em;
      padding: 0.5em 1em; }
    section.main-content .view-id-nieuws.view-display-id-page .views-row .news-item-image {
      float: left;
      width: 25%; }
      section.main-content .view-id-nieuws.view-display-id-page .views-row .news-item-image img {
        box-shadow: 0 1px 1px #999999; }
    section.main-content .view-id-nieuws.view-display-id-page .views-row .news-item-body {
      float: left;
      width: 75%; }
  section.main-content .view-id-nieuws.view-display-id-block_1 {
    padding-top: 2em; }
    section.main-content .view-id-nieuws.view-display-id-block_1 .views-row {
      float: left;
      padding-bottom: 12px;
      width: 50%; }
      section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .views-field-title h3,
      section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .views-field-title {
        display: inline-block;
        padding-bottom: 0.3em; }
      section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .views-field-field-datum {
        clear: right;
        float: right;
        font-size: 0.9em;
        font-style: italic; }
      section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .views-more-link {
        background: #00b5e4;
        color: #ffffff;
        display: inline-block;
        float: right;
        margin: 0.5em;
        padding: 0.5em 1em; }
      section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .news-item-image {
        float: left;
        width: 25%; }
        section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .news-item-image img {
          box-shadow: 0 1px 1px #999999; }
      section.main-content .view-id-nieuws.view-display-id-block_1 .views-row .news-item-body {
        float: left;
        padding-left: 1em;
        width: 75%; }
    section.main-content .view-id-nieuws.view-display-id-block_1 .views-row:nth-child(1) {
      padding-right: 5%; }

/** Non-front pages */
section.main-content.sub-page {
  background: #eef1f4;
  line-height: 1.6em;
  font-size: 14px;
  padding-top: 0; }
  section.main-content.sub-page .region-content {
    padding: 15px; }
  section.main-content.sub-page .menu-name-main-menu ul.menu {
    bottom: 1px;
    position: relative; }
    section.main-content.sub-page .menu-name-main-menu ul.menu li {
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 0;
      padding: 0 8px;
      transition: all .5s ease; }
      section.main-content.sub-page .menu-name-main-menu ul.menu li ul {
        display: none; }
      section.main-content.sub-page .menu-name-main-menu ul.menu li a {
        color: #8898a3;
        text-transform: uppercase;
        transition: all .5s ease; }
      section.main-content.sub-page .menu-name-main-menu ul.menu li a.active,
      section.main-content.sub-page .menu-name-main-menu ul.menu li a:hover {
        transition: all .5s ease; }
    section.main-content.sub-page .menu-name-main-menu ul.menu li.active-trail,
    section.main-content.sub-page .menu-name-main-menu ul.menu li:hover {
      background: #ffffff;
      transition: all .5s ease; }
      section.main-content.sub-page .menu-name-main-menu ul.menu li.active-trail a,
      section.main-content.sub-page .menu-name-main-menu ul.menu li:hover a {
        color: #184353; }
  section.main-content.sub-page div.node {
    padding-top: 1em; }
  section.main-content.sub-page .field-name-field-pagina-afbeelding {
    float: right;
    padding-left: 8px; }
    section.main-content.sub-page .field-name-field-pagina-afbeelding img {
      box-shadow: 0 1px 1px #999999; }
  section.main-content.sub-page p {
    margin-top: 0; }
  section.main-content.sub-page h3 {
    color: #00b5e4;
    font-size: 1.2em;
    font-weight: 600;
    padding-bottom: 3px;
    text-transform: uppercase; }
  section.main-content.sub-page h4 {
    color: #00b5e4;
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 3px;
    text-transform: uppercase; }
  section.main-content.sub-page .region-content .block-menu-block .menu-name-main-menu ul.menu li {
    background: #00b5e4;
    display: inline-block;
    vertical-align: top;
    height: 130px;
    margin: 0.8%;
    min-width: 165px;
    width: 20%;
    word-wrap: break-word; }
    section.main-content.sub-page .region-content .block-menu-block .menu-name-main-menu ul.menu li a {
      color: #eef1f4;
      display: block;
      font-size: 1.3em;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1.2em;
      padding: 10px; }

/** Search page */
.view-zoekresultaten .views-row {
  margin: 15px 0 2em 0; }
  .view-zoekresultaten .views-row a.views-more-link {
    float: right;
    background: #fbb900;
    color: #184353;
    font-weight: 600;
    line-height: 21px;
    margin-top: 2em;
    padding: 0 5px;
    text-transform: uppercase; }

/** Sidebar */
aside {
  background: #dfe4ea;
  float: left;
  font-size: 14px;
  padding: 12px;
  width: 27.33%;
  /** Testimonials */ }
  aside .block h2 {
    font-size: 1.2em;
    font-weight: 800;
    padding: 8px; }
  aside .block ul.menu li {
    margin: 0;
    padding: 8px;
    transition: all .5s ease;
    width: 100%; }
  aside .block ul.menu li:nth-child(even) {
    background: #eef1f4; }
  aside .block ul.menu li:hover {
    background: #00b5e4;
    cursor: pointer;
    transition: all .5s ease; }
    aside .block ul.menu li:hover a {
      color: #eef1f4; }
  aside .view-testimonials {
    padding: 0 15px; }
    aside .view-testimonials .views-field-field-afbeelding-testimonial {
      text-align: center; }
      aside .view-testimonials .views-field-field-afbeelding-testimonial img {
        border-radius: 50%; }
    aside .view-testimonials .views-field-field-quote {
      font-style: italic;
      line-height: 1.3em; }
      aside .view-testimonials .views-field-field-quote p {
        margin-bottom: 2px; }
      aside .view-testimonials .views-field-field-quote p:before {
        content: "\f10d";
        font-family: 'FontAwesome';
        padding-right: 5px; }
      aside .view-testimonials .views-field-field-quote p:after {
        content: "\f10e";
        font-family: 'FontAwesome';
        padding-left: 5px; }
    aside .view-testimonials .testimonial-person {
      font-weight: 600;
      text-align: right; }

/** Flexslider */
.flexslider {
  border: 0;
  box-shadow: none;
  margin: 0; }

/** Latest tweets */
.view-laatste-tweets ul .views-field-text {
  margin-bottom: 1em; }

/** Locations */
.view-Locaties div.location-list ul li {
  border-bottom: 1px solid #8898a3;
  margin-bottom: 2em;
  padding-bottom: 2em;
  position: relative; }
  .view-Locaties div.location-list ul li .location-blok,
  .view-Locaties div.location-list ul li .location-map,
  .view-Locaties div.location-list ul li .tour {
    float: left;
    width: 33%; }
  .view-Locaties div.location-list ul li .location-blok {
    height: 150px;
    vertical-align: middle; }
  .view-Locaties div.location-list ul li .geolocation-map {
    margin: 0; }
  .view-Locaties div.location-list ul li .route-links {
    bottom: 2em;
    position: absolute; }
    .view-Locaties div.location-list ul li .route-links a {
      display: inline-block;
      text-align: center;
      width: 80px; }

.view-Locaties #geolocation-views-locaties-block-1 img {
  max-width: none; }

.view-Locaties #geolocation-views-locaties-block-1 > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(2) {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 7px 0px !important; }

.view-Locaties #geolocation-views-locaties-block-1 > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(4),
.view-Locaties #geolocation-views-locaties-block-1 > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(2),
.view-Locaties #geolocation-views-locaties-block-1 > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div {
  width: 280px !important; }

.view-Locaties .gm-style-iw {
  width: 240px !important; }

.view-Locaties .geolocation-map-info .info {
  margin-bottom: 1em; }

.view-Locaties .geolocation-map-info .ov,
.view-Locaties .geolocation-map-info .route,
.view-Locaties .geolocation-map-info .tour {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 70px; }

.node-locatie .field {
  padding: 0.5em 0; }

/** Footer */
footer {
  border-top: 2px solid #8898a3;
  font-size: 0.9em;
  line-height: 1.5em;
  margin-top: 30px;
  padding-top: 15px; }
  footer section {
    float: left; }
  footer section.footer-first {
    width: 60%; }
    footer section.footer-first .block {
      display: inline-block;
      padding-right: 2%;
      vertical-align: top;
      width: 32%; }
      footer section.footer-first .block ul.menu li {
        margin: 0; }
  footer section.footer-second {
    width: 30%; }
  footer section.footer-third {
    float: right;
    width: 10%; }

/** Copyright bar */
.copyright {
  background: #00b5e4;
  color: #eef1f4;
  font-size: 12px;
  line-height: 40px;
  margin-top: 20px; }
  .copyright section {
    width: 50%; }
    .copyright section a {
      color: #eef1f4;
      text-decoration: underline; }
  .copyright section.copyright-left {
    float: left; }
  .copyright section.copyright-right {
    float: right; }
    .copyright section.copyright-right ul.menu {
      padding-top: 12px;
      text-align: right;
      width: 100%; }
      .copyright section.copyright-right ul.menu li {
        display: inline-block;
        line-height: 40px; }
        .copyright section.copyright-right ul.menu li a {
          color: #eef1f4; }
      .copyright section.copyright-right ul.menu li:after {
        content: " | ";
        padding-left: 10px; }
      .copyright section.copyright-right ul.menu li:last-child:after {
        display: none; }

.online-behandelingen .views-row {
  display: inline-block;
  vertical-align: top;
  width: 48%; }
  .online-behandelingen .views-row img {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2); }
  .online-behandelingen .views-row.views-row-even {
    padding-left: 2%; }
  .online-behandelingen .views-row a {
    background: #00b5e4;
    border-radius: 3px;
    display: block;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 1em 0;
    padding: 8px 12px;
    text-align: center;
    text-transform: uppercase; }

a#mobile-menu {
  background: #111111;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: none;
  left: 0;
  padding: 10px 6px;
  position: fixed;
  left: 0;
  z-index: 9998; }

@media all and (max-width: 900px) {
  header {
    padding-bottom: 2em; }
  nav {
    width: 100%; }
    nav div {
      margin: 0 auto; } }

@media all and (min-width: 768px) {
  section.main-content .view-landingspagina .views-field-field-afbeelding {
    display: block; }
  section.main-content .view-landingspagina .views-field-field-afbeelding-1 {
    display: none; } }

@media all and (max-width: 760px) {
  nav {
    text-align: center; }
    nav select {
      border: 0;
      height: 32px;
      line-height: 1em;
      margin-top: 1em;
      padding: 0 0 0 40px; } }

@media all and (max-width: 768px) {
  section.main-content .view-landingspagina {
    max-height: none;
    overflow: visible; }
    section.main-content .view-landingspagina .views-field-field-afbeelding {
      display: none; }
    section.main-content .view-landingspagina .views-field-field-afbeelding-1 {
      display: block; }
    section.main-content .view-landingspagina .views-field-view {
      position: inherit; }
      section.main-content .view-landingspagina .views-field-view .views-row {
        display: inline-block;
        width: 30%; }
  section.main-content .view-landingspagina:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  section.main-content .view-id-nieuws.view-display-id-block_1 .views-row {
    width: 100%; }
  footer section.footer-second {
    width: 40%; }
  footer section.footer-third {
    clear: both; }
  div.copyright section {
    width: 100%;
    text-align: center; }
  div.copyright section.copyright-right ul.menu {
    text-align: center; } }

@media all and (max-width: 665px) {
  header .breadcrumb-wrapper {
    display: none; }
  header section.header-top .block {
    clear: both;
    margin-bottom: 1em;
    text-align: right;
    width: 100%; }
    header section.header-top .block ul.menu li {
      padding-left: 15px;
      padding-right: 0; }
  section.main-content .view-landingspagina .views-field-view .views-row {
    display: inline-block;
    width: 46%; }
  section.main-content.with-sidebar {
    float: none;
    margin-right: 0;
    width: 100%; }
  aside {
    float: none;
    margin-top: 1em;
    width: 100%; } }

@media all and (max-width: 480px) {
  header section {
    text-align: center;
    width: 100%; }
  header section.header-top {
    display: none; }
  a#mobile-menu {
    color: #ffffff;
    display: inline; }
  section.main-content .view-landingspagina .views-field-view .views-row {
    display: inline-block;
    width: 95%; }
    section.main-content .view-landingspagina .views-field-view .views-row .views-field-name .field-content a {
      height: auto;
      padding: 20px 10px; }
  footer section.footer {
    margin: 2em auto;
    width: 100%; }
    footer section.footer .block {
      margin-bottom: 2em;
      width: 100%; }
      footer section.footer .block h2 {
        text-align: center; }
      footer section.footer .block ul.menu li {
        text-align: center; }
  footer section.footer-first {
    margin: 0 auto;
    text-align: center; }
  footer section.footer-second,
  footer section.footer-third {
    text-align: center; } }
